<template>
  <div class="game-container">
    <SpeakingSentence
      :gameList="gameList"
      :isClick="false"
      :hasMenu="false"
      :titleInfo="titleInfo"
    />
  </div>
</template>

<script>
import SpeakingSentence from "@/components/Course/CoursePage/SpeakingSentence";
export default {
  data() {
    return {
      gameList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-60-2/background.svg`),
        },
      ],
      titleInfo: {
        pinyin: "wǒ de Zhōngwén kè",
        hanzi: "我的中文课",
      },
    };
  },
  components: {
    SpeakingSentence,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
