var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-container" },
    [
      _c("SpeakingSentence", {
        attrs: { buttonList: _vm.buttonList, gameList: _vm.gameList },
        on: { initGame: _vm.initGame, showSentence: _vm.showSentence },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }