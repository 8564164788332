var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-container" },
    [
      _c("ScratchPicGame", {
        attrs: {
          gameList: _vm.gameList,
          starNum: 4,
          gameInfo: _vm.gameInfo,
          buttonList: _vm.buttonList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }