var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-container" },
    [
      _c("DragGame", {
        attrs: {
          envelopeList: _vm.envelopeList,
          mailboxList: _vm.mailboxList,
          dragNum: _vm.dragNum,
        },
        on: {
          randomOrder: _vm.handleRandomOrder,
          socketRandom: _vm.handleSocketRandom,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }