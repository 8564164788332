var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-container" },
    [
      _c("DragStrokeToImgGame", {
        attrs: {
          chineseList: _vm.chineseList,
          strokeList: _vm.strokeList,
          bgImg: _vm.bgImg,
          mapList: _vm.mapList,
          answersfound: _vm.answersFoundSum,
        },
        on: { handleDropEvent: _vm.handleDropEvent },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }