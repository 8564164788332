<template>
  <div class="bye-page-container">
    <StartStop :position="positionStr" :pageInfo="pageInfoObj" :chapterNo="8" />
  </div>
</template>

<script>
import StartStop from "@/components/Course/CoursePage/StartStop";
export default {
  data() {
    return {
      positionStr: "end",
      pageInfoObj: {
        pinyin: "Zàijiàn",
        hanzi: "再见",
        english: "",
      },
    };
  },
  components: {
    StartStop,
  },
};
</script>

<style lang="scss" scoped>
.bye-page-container {
  width: 100%;
  height: 100%;
}
</style>