<template>
  <div class="syllavus-A-cotainer">
    <SyllabusPage :wordList="wordListData" :isPageA="false" />
  </div>
</template>

<script>
import SyllabusPage from "@/components/Course/CoursePage/SyllabusPage";

export default {
  name: "SyllabusPage-lesson-1-A",
  data() {
    return {
      wordListData: [
        {
          title: "复习巩固",
          isReview: true,
          contentList: [
            [
              {
                pinyin: "cíhuì",
                hanzi: "词汇",
                symbol: "",
                group: 0,
                line: 0,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 0,
                line: 0,
                index: 1,
                symbolType: 2,
              },
              {
              pinyin: "xiàn ài",
              hanzi: "现在",
              symbol: "",
              group: 0,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 1,
              symbolType: 1,
            },
            {
              pinyin: "diǎn",
              hanzi: "点",
              symbol: "",
              group: 0,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 3,
              symbolType: 1,
            },
            {
              pinyin: "fēn",
              hanzi: "分",
              symbol: "",
              group: 0,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 5,
              symbolType: 1,
            },
            {
              pinyin: "jiàn(miàn)",
              hanzi: "见(面)",
              symbol: "",
              group: 0,
              index: 6,
              symbolType: 0,
            },

              // symbolType: 0word 1、 2： 3 “” 4！ 5…… 6。
            ],
            [
              {
                pinyin: "jùxíng",
                hanzi: "句型",
                symbol: "",
                group: 1,
                line: 1,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 1,
                index: 1,
                symbolType: 2,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "“",
                group: 1,
                line: 1,
                index: 2,
                symbolType: 3,
              },
              {
                pinyin: "xiàn",
                hanzi: "现",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "zài",
                hanzi: "在",
                symbol: "",
                group: 1,
                line: 1,
                index: 4,
                symbolType: 0,
              },
              {
                pinyin: "jǐ",
                hanzi: "几",
                symbol: "",
                group: 1,
                line: 1,
                index: 4,
                symbolType: 0,
              },
              {
                pinyin: "diǎn",
                hanzi: "点",
                symbol: "",
                group: 1,
                line: 1,
                index: 4,
                symbolType: 0,
              },
              
              {
                pinyin: "",
                hanzi: "",
                symbol: "”",
                group: 1,
                line: 1,
                index: 6,
                symbolType: 7,
              },
              {
                pinyin: "tí wèn",
                hanzi: "提问",
                symbol: "",
                group: 1,
                line: 1,
                index: 7,
                symbolType: 0,
              },
              {
                pinyin: "shí jiān",
                hanzi: "时间",
                symbol: "",
                group: 1,
                line: 1,
                index: 8,
                symbolType: 0,
              },
              {
                pinyin: "bìng",
                hanzi: "并",
                symbol: "",
                group: 1,
                line: 1,
                index: 8,
                symbolType: 0,
              },
              {
                pinyin: "huí dá",
                hanzi: "回答",
                symbol: "",
                group: 1,
                line: 1,
                index: 8,
                symbolType: 0,
              },
               {
                pinyin: "",
                name: "",
                symbol: "。",
                group: 1,
                line: 1,
                index: 6,
                symbolType: 6,
              },
            ],
          ],
        },
        {
          title: "我知道",
          isReview: false,
          content: [
            // {
            //   pinyin: "nǐ",
            //   hanzi: "你",
            //   symbol: "",
            //   id: 1,
            //   symbolType: 0,
            // },

            // symbolType: 0word 1、 2： 3 “ 4！ 5…… 6。7”

            {
              pinyin: "jīběn",
              hanzi: "基本",
              symbol: "",
              group: 1,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "bǐhuà",
              hanzi: "笔画",
              symbol: "",
              group: 1,
              index: 1,
              symbolType: 0,
            },
            {
              pinyin: "héngzhéwāngōu",
              hanzi: "",
              symbol: "",
              group: 1,
              index: 2,
              isHavePinyin: true,
              imgUrl: require("@/assets/img/11-Syllabus/lesson-60-hengzhewangou.svg"),
              symbolType: 8,
            },
            
            {
              pinyin: "de",
              hanzi: "的",
              symbol: "",
              group: 1,
              index: 5,
              symbolType: 0,
            },
            {
              pinyin: "míngchēng",
              hanzi: "名称",
              symbol: "",
              group: 1,
              index: 6,
              symbolType: 0,
            },
            {
              pinyin: "jí",
              hanzi: "及",
              symbol: "",
              group: 1,
              index: 7,
              symbolType: 0,
            },
            {
              pinyin: "shūxiě",
              hanzi: "书写",
              symbol: "",
              group: 1,
              index: 8,
              symbolType: 0,
            },
            {
              pinyin: "guīzé",
              hanzi: "规则",
              symbol: "",
              group: 1,
              index: 9,
              symbolType: 0,
            },
          ],
        },
        {
          title: "我会写",
          isReview: false,
          content: [
            // {
            //   pinyin: "nǐ",
            //   hanzi: "你",
            //   symbol: "",
            //   id: 1,
            //   symbolType: 0,
            // },

            {
              pinyin: "ànzhào",
              hanzi: "按照",
              symbol: "",
              group: 2,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "zhèngquè",
              hanzi: "正确",
              symbol: "",
              group: 2,
              index: 1,
              symbolType: 0,
            },

            {
              pinyin: "bǐshùn",
              hanzi: "笔顺",
              symbol: "",
              group: 2,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "shūxiě",
              hanzi: "书写",
              symbol: "",
              group: 2,
              index: 3,
              symbolType: 0,
            },
            {
              pinyin: "diǎn",
              hanzi: "",
              symbol: "",
              group: 2,
              index: 4,
              isHavePinyin: true,
              imgUrl: require("@/assets/img/11-Syllabus/lesson-60-dian.svg"),
              symbolType: 8,
            },
             {
              pinyin: "shūxiě",
              hanzi: "和",
              symbol: "",
              group: 2,
              index: 3,
              symbolType: 0,
            },
             {
              pinyin: "fēn",
              hanzi: "",
              symbol: "",
              group: 2,
              index: 4,
              isHavePinyin: true,
              imgUrl: require("@/assets/img/11-Syllabus/lesson-60-fen.svg"),
              symbolType: 8,
            },
          ],
        },
      ],
    };
  },
  components: {
    SyllabusPage,
  },
};
</script>

<style lang="scss" scoped>
.syllavus-A-cotainer {
  width: 100%;
  height: 100%;
}
</style>